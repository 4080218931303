<template>
  <div>
    <v-toolbar flat dense>
      <v-btn text outlined depressed @click="shuffle" class="mr-2"
        ><v-icon left>mdi-shuffle</v-icon> mischen</v-btn
      >
      <v-btn text outlined depressed @click="flipAll(true)" class="mr-2"
        ><v-icon left>mdi-cards-playing</v-icon> alle aufdecken</v-btn
      >
      <v-btn text outlined depressed @click="flipAll(false)" class="mr-2"
        ><v-icon left>mdi-cards</v-icon> alle verdecken</v-btn
      >
      <v-spacer />
      <v-progress-circular
        :size="50"
        :width="6"
        :value="progress"
        color="primary"
      >
        <small>{{ countItemsHidden }}/{{ items.length }}</small>
      </v-progress-circular>
    </v-toolbar>

    <transition-group
      name="flip-list"
      tag="div"
      class="mt-4 d-flex justify-center flex-wrap"
    >
      <FlipCard
        v-for="item in itemsVisible"
        v-model="item.flipped"
        :key="`card${item.id}`"
      >
        <template slot="front">
          <v-card
            :width="width"
            :height="height"
            @click="item.flipped = true"
            :ripple="false"
            elevation="1"
          >
            <PortraitImage large :value="item" />
          </v-card>
        </template>
        <template slot="back">
          <v-card
            :width="width"
            :height="height"
            @click="item.flipped = false"
            :ripple="false"
            elevation="1"
            class="d-flex flex-column justify-center align-center"
          >
            <div class="text-center text-h5 mb-4">
              <PersonName :value="item" />
            </div>

            <div>
              <v-btn
                x-large
                icon
                color="danger"
                dark
                @click="again(item)"
                class="mr-1"
                ><v-icon>mdi-close</v-icon></v-btn
              >

              <v-btn
                x-large
                icon
                color="success"
                @click.stop="hide(item)"
                class="ml-1"
                ><v-icon>mdi-check</v-icon></v-btn
              >
            </div>
          </v-card>
        </template>
      </FlipCard>
      <FlipCard v-model="stapleFlipped" :key="'staple'">
        <template slot="front">
          <v-card
            :outlined="countItemsHidden == 0"
            :width="width"
            :height="height"
            @click="stapleFlipped = true"
            :ripple="false"
            class="d-flex flex-column justify-center align-center"
            :elevation="(countItemsHidden + 1) / 2"
          >
            <div class="text-center text-h5 mb-4">
              {{ countItemsHidden }}
            </div>

            <div>abgelegt</div>
          </v-card>
        </template>
        <template slot="back">
          <v-card
            :outlined="countItemsHidden == 0"
            :width="width"
            :height="height"
            @click="stapleFlipped = false"
            :ripple="false"
            :elevation="(countItemsHidden + 1) / 2"
            class="d-flex flex-column justify-center align-center"
          >
            <v-btn
              text
              color="info"
              @click="reset"
              :disabled="countItemsHidden == 0"
              >verteilen</v-btn
            >
          </v-card>
        </template>
      </FlipCard>
    </transition-group>
  </div>
</template>
<script>
import FlipCard from "@/components/FlipCard";
import PersonName from "common/components/PersonName";
import PortraitImage from "common/components/PortraitImage.vue";

export default {
  name: "Flipper",
  components: { FlipCard, PersonName, PortraitImage },
  props: {
    name: {
      type: String,
      default: "Flipper",
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: Number,
      default: 240,
    },
    width: {
      type: Number,
      default: 180,
    },
  },
  data() {
    return { items: [], stapleFlipped: false };
  },
  computed: {
    progress() {
      if (this.items && this.items.length > 0) {
        return 100 * (this.countItemsHidden / this.items.length);
      }
      return 0;
    },
    itemsVisible() {
      return this.items.filter((student) => !student.hidden);
    },
    countItemsHidden() {
      if (this.items.length > 0) {
        return this.items.filter((student) => student.hidden).length;
      }
      return 0;
    },
  },
  watch: {
    value() {
      this.prepare();
    },
  },
  methods: {
    prepare() {
      this.items = [];
      this.value.forEach((item) => {
        let hidden = false;
        if (localStorage.getItem(this.name + "_person" + item.id)) {
          const status = localStorage.getItem(this.name + "_person" + item.id);
          if (status === "hidden") {
            hidden = true;
          }
        }
        this.items.push({ ...item, flipped: false, hidden: hidden });
      });
    },
    again(item) {
      this.items.push(this.items.splice(this.items.indexOf(item), 1)[0]);
    },
    hide(item) {
      localStorage.setItem(this.name + "_person" + item.id, "hidden");
      this.items.push(this.items.splice(this.items.indexOf(item), 1)[0]);
      window.setTimeout(() => {
        item.hidden = true;
      }, 1100);
    },
    flipAll(value) {
      let time = 0;
      for (let item of this.items) {
        setTimeout(() => {
          item.flipped = value;
        }, time);
        time = time + 80;
      }
    },
    reset() {
      let time = 0;
      for (let item of this.items) {
        setTimeout(() => {
          item.flipped = false;
          item.hidden = false;
          localStorage.removeItem(this.name + "_person" + item.id);
        }, time);
        time = time + 80;
      }
    },
    shuffle() {
      var currentIndex = this.items.length,
        temporaryValue,
        randomIndex;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = this.items[currentIndex];
        this.$set(this.items, currentIndex, this.items[randomIndex]);
        this.$set(this.items, randomIndex, temporaryValue);
      }
    },
  },
  created() {
    this.prepare();
  },
};
</script>
<style scoped>
.flip-list-move {
  transition: transform 1s;
}
.d-flex {
  gap: 1rem;
}
</style>
